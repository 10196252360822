import makeStore from "@mborecki/react-create-store";
import GEO_POINTS from "./game-points";
import reducer from "./reducer";
import { SCENARIO } from "./scenario";
import { GamePoint } from "./types";

const GAME_MODULE_LS_KEY = 'raszyn:game-v3'

export type GameModuleState = {
    gamePoints: GamePoint[],
    activeGameStep?: string,
    gameStarted: boolean,
    gameComplete: boolean
}

export const initGameModuleState = {
    gamePoints: GEO_POINTS,
    activeGameStep: SCENARIO.startStep,
    gameStarted: false,
    gameComplete: false
}


export enum GAME_MODULE_ACTION {
    SET_ACTIVE_POINT_POSITION,
    START_GAME,
    RESET_GAME,
    SET_GAME_STEP,
    END_GAME
}

export type GameModuleAction = {
    type: GAME_MODULE_ACTION,
    payload?: any
}

const [
    GameModuleProvider,
    useGameModuleDispatch,
    useGameModuleState
] = makeStore(reducer, initGameModuleState, GAME_MODULE_LS_KEY)


export {
    GameModuleProvider,
    useGameModuleDispatch,
    useGameModuleState
}
