import Box from "../../components/box";

export default function AccessibillytyPage() {
    return <>
        <Box>
            <h2>Deklaracja dostepności</h2>

            <p id="a11y-wstep">
                <span id="a11y-podmiot">Stowarzyszenie „Projekt Raszyn”</span> w Raszynie zobowiązuje się zapewnić dostępność swojej strony internetowej zgodnie z ustawą z dnia 4 kwietnia 2019 r. o dostępności stron internetowych i aplikacji mobilnych podmiotów publicznych. Oświadczenie w sprawie dostępności ma zastosowanie do strony internetowej <span id="a11y-url">raszyngra.pl</span>.
            </p>

            <p>
                Data publikacji strony internetowej: <span id="a11y-data-publikacja">2021-12-23</span><br />
                Data ostatniej aktualizacji strony internetowej: <span id="a11y-data-aktualizacja">2022-01-20</span>
            </p>

            <p>
                Strona internetowa jest <span id="a11y-status">częściowo zgodna</span> z wytycznymi dostępności stron internetowych i aplikacji mobilnych z powodu niezgodności wymienionych poniżej:
            </p>

            <ul>
                <li>kolory i tła nie mają wystarczającego współczynnika kontrastu</li>
                <li>zdjęcia i grafiki nie zawierają opisów alternatywnych</li>
                <li>niektóre odnośniki na stronie nie są wyróżnione podkreśleniem</li>
            </ul>

            <p>
                Gra mobilna na stronie, nie jest zgodna z wytycznymi dostępności stron internetowych i aplikacji mobilnych. Brak dostępności wynika z:
            </p>
            <ul>
                <li>geolokalizacyjnego charakteru gry, który wymaga od grającego aby ten dotarł do określonego punktu w terenie w celu uruchomienia gry i jej dalszych etapów</li>
                <li>zadania w grze wymagają użycia urządzenia mobilnego z ekranem dotykowym i aparatem</li>

            </ul>

            <p>
                Oświadczenie sporządzono dnia: <span id="a11y-data-sporzadzenie">2022-01-28</span><br />
                Deklarację sporządzono na podstawie samooceny.
            </p>

            <h3>Skróty klawiszowe</h3>
            <p>
                Na stronie internetowej można używać standardowych skrótów klawiaturowych przeglądarki.
            </p>

            <h3 id="a11y-kontakt">Informacje zwrotne i dane kontaktowe</h3>
            <p>
                W przypadku problemów z dostępnością strony internetowej prosimy o kontakt. Zgłoś to do <span id="a11y-osoba">Doroty Betko</span>, mailowo - <span id="a11y-email">bikbox@wp.pl</span>.
            </p>
            <p>
                W zgłoszeniu podaj:
            </p>
            <ul>
                <li>swoje imię i nazwisko,</li>
                <li>swoje dane kontaktowe (np. numer telefonu, e-mail),</li>
                <li>dokładny adres strony internetowej, na której jest niedostępny element lub treść,</li>
                <li>opis na czym polega problem i jaki sposób jego rozwiązania byłby dla Ciebie najwygodniejszy.</li>
            </ul>

            <h3>Obsługa wniosków i skarg związanych z dostępnością</h3>

            <div id="a11y-procedura">
                <p>
                    Na Twoje zgłoszenie odpowiemy najszybciej jak to możliwe, nie później niż w ciągu 7 dni od jego otrzymania.
                </p>
                <p>
                    Jeżeli ten termin będzie dla nas zbyt krótki poinformujemy Cię o tym. W tej informacji podamy nowy termin, do którego poprawimy zgłoszone przez Ciebie błędy lub przygotujemy informacje w alternatywny sposób. Ten nowy termin nie będzie dłuższy niż 2 miesiące.
                </p>
                <p>
                    Jeżeli nie będziemy w stanie zapewnić dostępności cyfrowej strony internetowej lub treści, z Twojego zgłoszeniu, zaproponujemy Ci dostęp do nich w alternatywny sposób.
                </p>
                <p>
                    Jeżeli nasze działania nie będą dla Ciebie zadowalające, możesz zgłosić skargę. Jeżeli nadal będziesz mieć uwagi do naszych działań związane z dostępnością cyfrową możesz zgłosić je do <a href="https://bip.brpo.gov.pl/pl/content/jak-zglosic-sie-do-rzecznika-praw-obywatelskich">Rzecznika Praw Obywatelskich</a>.
                </p>
            </div>

            <h2 id="a11y-architektura">Dostępność architektoniczna</h2>

            <p>
                Przestrzeń realizacji gry zlokalizowana jest w Raszynie, w przeważającej części w  plenerze i rozpoczyna się przed kapliczką przy Cypriana Godebskiego 19. W pobliżu znajduje się parking. W niektórych lokalizacjach znajdują się utrudnienia dla osób poruszającym się na wózkach, w postaci braku utwardzonej drogi.<br />
                Jedna lokalizacja znajduje się na dziedzińcu budynku Centrum Integracji Społeczno-Kulturalnej – Austeria.
            </p>

            <p>
                Budynek Centrum Integracji Społeczno-Kulturalnej – Austeria, Al. Krakowska 1, 05-090 Raszyn.
            </p>

            <ul>
                <li>Budynek posiada 2 wejścia. Wejście od al. Krakowskiej jest niedostępne dla osób poruszających się na wózkach – przed wejściem jest stopień. Wejście od dziedzińca wyposażone jest w „kładkę nadproża” ułatwiającą przejazd wózkiem przez próg bramy. Bramę musi otworzyć od środka pracownik. Samodzielny przejazd przez próg drzwi osadzonych w skrzydle bramy nie jest możliwy (zarówno w przypadku wózków inwalidzkich, jak i wózków dziecięcych). Próg ten stanowi również przeszkodę dla osób starszych lub kalekich.</li>
                <li>Dla osób na wózkach dostępne są korytarze i pomieszczenia na całej powierzchni budynku. Podłoga w holu na parterze jest wyłożona w całości kostką bazaltową utrudniającą przejazd wózkiem.</li>
                <li>W budynku nie ma oznaczeń w alfabecie brajla (prócz windy) ani oznaczeń kontrastowych lub w druku powiększonym dla osób niewidomych i słabowidzących.</li>
                <li>Do budynku i wszystkich jego pomieszczeń można wejść z psem asystującym i psem przewodnikiem.</li>
                <li>W budynku nie ma tablic informacyjnych na temat rozkładu pomieszczeń w formie wizualnej oraz dotykowej lub głosowej.</li>
                <li>W budynku nie ma informacji o kierunkach i drogach ewakuacji w formie dotykowej i głosowej. Nie ma pokoi oczekiwania na ewakuację dostosowanych dla osób ze szczególnymi potrzebami.</li>
                <li>Przed budynkiem nie ma wyznaczonych miejsc parkingowych dla osób niepełnosprawnych. Cały parking wyłożony jest kostką granitową utrudniającą poruszanie się na wózku.</li>
            </ul>
        </Box>
    </>
}
